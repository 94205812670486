<template>
    <div class="row">
        <div class="col-lg-5">
            <div class="info-wrapper">
                <h1 class="cs-title" v-if="data.title">
                    <ColorSplit :data="data.title" />
                </h1>
                <span class="cs-text" v-if="data.body" v-html="data.body"></span>
            </div>
        </div>
        <div class="col-lg-7">
            <CSForm v-if="display == 'form'" :id="data.form" :downloaded="downloaded"></CSForm>
            <a class="download" v-if="display == 'download'" :href="data.downloadable" download>
                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="a9148db4-8702-4948-b3d7-c33f0782daf4" data-name="Download"><path d="m28 24v-4a1 1 0 0 0 -2 0v4a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1-1v-4a1 1 0 0 0 -2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3zm-6.38-5.22-5 4a1 1 0 0 1 -1.24 0l-5-4a1 1 0 0 1 1.24-1.56l3.38 2.7v-13.92a1 1 0 0 1 2 0v13.92l3.38-2.7a1 1 0 1 1 1.24 1.56z"/></g></svg>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CSLandingPage",
        components: { 
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            CSForm: () => import("../components/CSForm.vue") 
        },
        data(){
            return {
                display: 'form'
            }
        },
        props: {
            data: {
                type: Object,
                default: () => { return {} },
            }
        },
        methods:{
            downloaded(){
                this.display = 'download'
            }
        },
        mounted(){
            console.log(this)
        }
    }

</script>

<style scoped lang="scss">

.info-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

h1.cs-title {
    width: 100%;
}

a.download {
    background: #eff0f3;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.download svg {
    max-width: 50%;
    max-height: 50%;
}
</style>